import React from 'react'
import styled from 'styled-components'
import Skill from "./Skill";
import {
    DiJava,
    DiJqueryLogo,
    DiNodejsSmall, DiOpenshift,
    DiReact, DiRuby,
    DiScrum, DiSwift
} from "react-icons/di";
import {
    SiAngular, SiApachemaven,
    SiCisco,
    SiCsharp,
    SiCss3, SiDynatrace, SiGradle, SiGrafana,
    SiHaskell, SiJunit5, SiMongodb, SiPandas,
    SiPostgresql,
    SiSelenium,
    SiSpring
} from "react-icons/si";
import {
    FaAws,
    FaConfluence,
    FaDocker,
    FaJenkins,
    FaLinux, FaNpm,
    FaPhp,
    FaPython,
    FaRProject,
    FaRust,
    FaVuejs
} from "react-icons/fa";
import {TbBrandJavascript, TbBrandKotlin, TbBrandTypescript, TbFileTypeJsx, TbFileTypeTsx} from "react-icons/tb";
import {BiGitMerge} from "react-icons/bi";
import {FiFigma, FiGitlab} from "react-icons/fi";
import {
    AiFillAndroid,
    AiFillApple,
    AiFillGithub,
    AiFillHtml5
} from "react-icons/ai";
import {ImWindows} from "react-icons/im";
import {GrMysql} from "react-icons/gr";
import {BsFiletypeSql, BsFiletypeXml, BsKanbanFill} from "react-icons/bs";
import {IoLogoBitbucket} from "react-icons/io";
import skills from './skills.json';

const iconMap = {
    DiJava,
    DiJqueryLogo,
    DiNodejsSmall,
    DiReact,
    DiRuby,
    DiScrum,
    DiSwift,
    SiAngular,
    SiApachemaven,
    SiCisco,
    SiCsharp,
    SiCss3,
    SiGradle,
    SiHaskell,
    SiJunit5,
    SiMongodb,
    SiPandas,
    SiPostgresql,
    SiSelenium,
    SiSpring,
    FaDocker,
    FaJenkins,
    FaLinux,
    FaPhp,
    FaPython,
    FaRProject,
    FaVuejs,
    TbBrandJavascript,
    TbBrandTypescript,
    BiGitMerge,
    FiFigma,
    FiGitlab,
    AiFillAndroid,
    AiFillApple,
    AiFillGithub,
    AiFillHtml5,
    ImWindows,
    GrMysql,
    BsFiletypeSql,
    BsFiletypeXml,
    IoLogoBitbucket,
    BsKanbanFill,
    SiGrafana,
    DiOpenshift,
    TbBrandKotlin,
    SiDynatrace,
    FaAws,
    FaRust,
    TbFileTypeJsx,
    TbFileTypeTsx,
    FaConfluence,
    FaNpm
};

const TechStack = () => {
    return (
        <Container id="techstack">
            <h1>Tech-<span style={{color: 'var(---secondary)'}}>Stack</span></h1>
            <Skills>
                {skills.map((skill, index) => (
                    <Skill
                        key={index}
                        Icon={iconMap[skill.Icon]}
                        Skill={skill.Skill}
                        Underline={skill.Underline}
                    />
                ))}
            </Skills>
        </Container>
    );
};

export default TechStack

const Container = styled.div`
  position: relative;
  padding: 2rem 0;
  width: 80%;
  max-width: 1280px;
  margin: 2rem auto 6rem;

  h1 {
    padding-bottom: 1.3rem;
  }

  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 3rem;
  }
`;

const Skills = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 2rem;

  @media (max-width: 650px) {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 1.5rem;
  }
`;

