import React from 'react';
import styled from 'styled-components';
import { IoMdClose } from "react-icons/io";

const TimeInfo = ({ position, skills, toggleInfo }) => {

    const handleClickOutside = (event) => {
        if (event.target.id === 'modal-background') {
            toggleInfo();
        }
    };

    return (
        <ModalBackground id="modal-background" onClick={handleClickOutside}>
            <InfoModal onClick={(e) => e.stopPropagation()}>
                <Header>
                    <h3>{position}</h3>
                    <CloseButton onClick={toggleInfo}><IoMdClose /></CloseButton>
                </Header>
                <Content>
                    <SkillsContainer>
                        {skills.map((skill, index) => (
                            <Skill key={index}>{skill}</Skill>
                        ))}
                    </SkillsContainer>
                </Content>
            </InfoModal>
        </ModalBackground>
    );
};

export default TimeInfo;

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const InfoModal = styled.div`
    background-color: var(---tertiary);
    color: var(---primary);
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    max-height: 600px;
    z-index: 101;
    border: 5px solid var(---secondary);
    position: relative;

    @media (max-width: 650px) {
        width: 300px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        flex-grow: 1;
        text-align: center;
        margin: 0;
    }
`;

const Content = styled.div`
    overflow-y: auto;
    max-height: 520px;
    padding: 10px;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: var(---tertiary);
    }

    ::-webkit-scrollbar-thumb {
        background: var(---secondary);
    }
`;

const CloseButton = styled.button`
    color: var(---secondary);
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
`;

const SkillsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
`;

const Skill = styled.p`
    background-color: var(---tertiary);
    border: 1px solid var(---secondary);
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px 0;
    flex: 1 1 auto;
    text-align: center;
`;
