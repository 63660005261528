import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import TimeInfo from "./TimeInfo";
import {FaInfoCircle} from "react-icons/fa";

const Timepoint = ({timepoint, index, showInfo, setShowInfo}) => {
    const {date_from, date_to, position, company, present, skills} = timepoint;
    const isEvenIndex = index % 2 === 1;

    const formatDate = (date) => {
        const options = {year: 'numeric', month: 'long'};
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const handleResize = () => {
        if (window.outerWidth <= 650) {
            return !isEvenIndex
                ? {marginRight: '0', display: 'flex', flexDirection: 'row-reverse'}
                : {marginLeft: '0', display: 'flex', flexDirection: 'row'};
        } else if (window.innerWidth <= 840) {
            return !isEvenIndex ? {marginRight: 'calc(60% - 20px)'} : {marginLeft: 'calc(60% - 20px)'};
        } else {
            return !isEvenIndex ? {marginRight: 'calc(40% - 20px)'} : {marginLeft: 'calc(40% - 20px)'};
        }
    };

    const [resizeStyle, setResizeStyle] = useState(
        handleResize()
    );

    useEffect(() => {
        const handleWindowResize = () => {
            const resizeStyle = handleResize();
            setResizeStyle(resizeStyle);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleResize]);

    return (
        <TimepointDiv style={resizeStyle}>
            {!isEvenIndex ? (
                <Content>
                    <FirstList>
                        {position !== '' ? <Position>{position}</Position> : null}
                        <InfoButton onClick={setShowInfo}><FaInfoCircle/></InfoButton>
                    </FirstList>
                    <DateDiv>
                        {!present ? `${formatDate(date_from)} - ${formatDate(date_to)}` : `${formatDate(date_from)} - Present`}
                    </DateDiv>
                    <Company>{company}</Company>
                </Content>
            ) : null}
            <Dot present={present}/>
            {isEvenIndex ? (
                <Content>
                    <FirstList>
                        {position !== '' ? <Position>{position}</Position> : null}
                        <InfoButton onClick={setShowInfo}><FaInfoCircle/></InfoButton>
                    </FirstList>
                    <DateDiv>
                        {!present ? `${formatDate(date_from)} - ${formatDate(date_to)}` : `${formatDate(date_from)} - Present`}
                    </DateDiv>
                    <Company>{company}</Company>
                </Content>
            ) : null}
            {showInfo && <TimeInfo position={position} skills={skills} toggleInfo={setShowInfo}/>}
        </TimepointDiv>
    );
};

export default Timepoint;

const TimepointDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 40%;

    @media (max-width: 840px) and (min-width: 651px) {
        width: 60%;
    }

    @media (max-width: 650px) {
        width: 100%;
    }
`;

const Dot = styled.div`
    height: 20px;
    width: 20px;
    display: inline-flex;
    flex: 0 0 auto;
    line-height: 80px;
    border-radius: 100%;
    background-color: ${(props) => (props.present ? 'var(---secondary)' : 'var(---primary)')};
    border: 3px solid var(---secondary);
`;

const Content = styled.div`
    background-color: var(---tertiary);
    color: var(---primary);
    padding: 10px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
    width: 100%;
`;

const DateDiv = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    color: var(---secondary);
`;

const Position = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

const Company = styled.div`
    font-style: italic;
`;

const InfoButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    color: var(---secondary);
    background-color: var(---tertiary);
    border: none;
    cursor: pointer;
    
    @media (max-width: 840px) {
        font-size: 16px;
    }
`;

const FirstList = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
`;
